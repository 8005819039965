<template>
    <div class="base-info main-content-wrap">
        <el-form ref="ruleForm"
            :model="ruleForm"
            label-width="120px"
            label-position="top"
            :disabled="isLoading">
            <div class="title-wrap">Basic Information</div>
            <div class="info-wrap">
                <div class="avator-wrap">
                    <rap-upload v-model="ruleForm.avator"
                        :params="{'centerType': 'usercenter'}"
                        :size="0.5">
                        <rap-image slot="reference"
                            class="avator-img"
                            :src="ruleForm.avator">
                        </rap-image>
                    </rap-upload>
                    <span class="edit"><i class="iconfont iconaddress_no"></i></span>
                </div>
                <el-row :gutter="30" class="label-form-items">
                    <el-col :span="8">
                        <el-form-item label="First name" prop="firstName">
                            <el-input v-model="ruleForm.firstName" clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="Last name" prop="lastName">
                            <el-input v-model="ruleForm.lastName" clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="Phone number" prop="phone">
                            <el-input v-model="ruleForm.phone" clearable></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
            <div class="title-wrap other">Change Password</div>
            <el-row :gutter="30" class="label-form-items">
                <el-col :span="8">
                    <el-form-item label="New Password" prop="password">
                        <el-input v-model="ruleForm.password" show-password clearable></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="Repeat new password" prop="repeatPwd">
                        <el-input v-model="ruleForm.repeatPwd" show-password clearable></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="30" class="label-form-items">
                <el-col :span="8">
                    <el-button size="''" :loading="isLoading" type="primary" @click="doSave">Save</el-button>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
export default {
    data () {
        return {
            ruleForm: {
                firstName: '',
                lastName: '',
                phone: '',
                password: '',
                repeatPwd: '',
                avator: ''
            },
            isLoading: false
        }
    },
    created () {
        this.reqUserInfo()
    },
    methods: {
        // 请求用户详情
        reqUserInfo () {
            this.isLoading = true
            this.$api.getUserInfo({}).then(res => {
                this.isLoading = false
                if (res.code === 0) {
                    const data = res.data
                    this.ruleForm.firstName = data.first_name || ''
                    this.ruleForm.lastName = data.last_name || ''
                    this.ruleForm.phone = data.mobile || ''
                    this.ruleForm.avator = data.header || ''
                }
            }).catch(() => {
                this.isLoading = false
            })
        },
        // 保存
        doSave () {
            this.$refs.ruleForm &&
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    if (this.ruleForm.password && this.ruleForm.password !== this.ruleForm.repeatPwd) {
                        return this.$notify.error('Change password is inconsistent！')
                    }
                    this.reqUpdateInfo()
                }
            })
        },
        // 保存请求
        reqUpdateInfo () {
            this.$api.updateUserInfoById({
                first_name: this.ruleForm.firstName,
                last_name: this.ruleForm.lastName,
                mobile: this.ruleForm.phone,
                password: this.ruleForm.password,
                rePassword: this.ruleForm.repeatPwd,
                header: this.ruleForm.avator
            }).then(res => {
                if (res.code === 0) {
                    if (this.ruleForm.password) {
                        this.$cookie.remove('USER_TOKEN')
                        localStorage.removeItem('USER_INFO')
                        localStorage.removeItem('IS_LOGIN')
                        this.$router.push({
                            path: '/login'
                        })
                    } else {
                        this.$store.dispatch('userCenter/GET_USERINFO_TYPE')
                    }
                    this.$notify.success('Update Success')
                } else {
                    this.$notify.error(res.message)
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.base-info {
    background: white;
    margin-top: 25px;
    margin-bottom: 25px;
    padding-bottom: 25px;
    border: 1px solid #dedede;
    border-radius: 2px;
    .title-wrap {
        padding: 24px 24px 15px 24px;
        font-size: 26px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #000000;
        border-bottom: 1px solid #dedede;
        &.other {
            padding-top: 8px;
        }
    }
    .info-wrap {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .avator-wrap {
            position: relative;
            padding: 0 20px;
            border-right: 1px solid $borderColor;
            .avator-img {
                height: 62px;
                width: 62px;
                border-radius: 100%;
                overflow: hidden;
            }
            .edit {
                position: absolute;
                right: 25px;
                bottom: 0;
                font-size: 20px;
                color: $mainPrimary;
            }
        }
        .el-row {
            flex-grow: 1;
            width: 100%;
        }
    }
    .label-form-items {
        padding: 9px 24px 0 24px;
        /deep/ .el-form-item__label {
            padding-bottom: 0;
        }
    }
    .el-button{
        width: 100%;
    }
}
</style>
